import { $fetch } from 'ofetch';

export const $api = () => {
    const config = useRuntimeConfig();
    const store = useGlobalStore();
    const lang = store.$state.lang === 'zh-CN' ? 'zh' : 'en';
    const jwt = store.$state.jwt ? `Bearer ${store.$state.jwt}` : '';
    console.log('$API => ', {
        jwt,
        language: lang,
        initData: store.$state.telegram.initData,
        wkey: store.$state.wkey,
        client: config.public.isDev ? 'uture-dev' : 'uture',
        baseURL: config.public.UTURE_BASE_URL,
    });
    
    return $fetch.create({
        headers: {
            'x-telegram-auth': process.client ? store.$state.telegram.initData : '',
            jwtToken: store.$state.jwt ? store.$state.jwt : '',
            lang: lang,
            client: config.public.isDev ? 'uture-dev' : 'uture',
            'x-whatsapp-auth': store.$state.wkey ? store.$state.wkey : '',
            // 'X-Telegram-Auth': 'query_id=AAFJGCQRAAAAAEkYJBFfwlXU&user=%7B%22id%22%3A287578185%2C%22first_name%22%3A%22Ice%22%2C%22last_name%22%3A%22Hazymoon%22%2C%22language_code%22%3A%22zh-hans%22%7D&auth_date=1685441064&hash=7dab42ff2c7b5641355dfd6940624754f5438ca90c72b7edb21b270ca992a97f'
        },
        baseURL: config.public.UTURE_BASE_URL,
        async onRequestError({ request, options, error }) {
            console.log('[$API fetch request error]', request, error)
            return Promise.reject(new Error(error.message));
        },
        async onResponseError({ request, response, options }: { request: any, response: any, options: any }) {
            console.log('[$API fetch response error]', request, response)
            return Promise.reject(new Error(response?._data?.msg || response.message));
        }
    })
}